import { ReactComponent as ArrowBlack } from './assets/icons/arrow-black.svg'
import { ReactComponent as Contract } from './assets/icons/contract.svg'
import { ReactComponent as Market } from './assets/icons/market.svg'
import { ReactComponent as Plan } from './assets/icons/plan.svg'
import { ReactComponent as Qualification } from './assets/icons/qualification.svg'
import { ReactComponent as Tender } from './assets/icons/tender.svg'
import { ReactComponent as Filter } from './assets/icons/filter.svg'
import { ReactComponent as Search } from './assets/icons/search.svg'
import { ReactComponent as CloseBadge } from './assets/icons/closeBadge.svg'
import { ReactComponent as User } from './assets/icons/user.svg'
import { ReactComponent as UserGrey } from './assets/icons/userGrey.svg'
import { ReactComponent as Burger } from './assets/icons/burger.svg'
import { ReactComponent as Close } from './assets/icons/close.svg'
import { ReactComponent as Info } from './assets/icons/info.svg'
import { ReactComponent as Star } from './assets/icons/star.svg'
import { ReactComponent as Print } from './assets/icons/print.svg'
import { ReactComponent as Eye } from './assets/icons/eye.svg'
import { ReactComponent as CalendarPlus } from './assets/icons/calendar-plus.svg'
import { ReactComponent as CheckWhite } from './assets/icons/checkWhite.svg'
import { ReactComponent as Dropdown } from './assets/icons/dropdown.svg'
import { ReactComponent as Attention } from './assets/icons/attention.svg'
import { ReactComponent as Settings } from './assets/icons/settings.svg'
import { ReactComponent as UserMenuNotifications } from './assets/icons/user_menu_notifications.svg'
import { ReactComponent as UserMenuExit } from './assets/icons/user_menu_exit.svg'
import { ReactComponent as Document } from './assets/icons/document.svg'
import { ReactComponent as Download } from './assets/icons/download.svg'
import { ReactComponent as Upload } from './assets/icons/upload.svg'
import { ReactComponent as Winner } from './assets/icons/winner.svg'
import { ReactComponent as MinusCircle } from './assets/icons/minus-circle.svg'
import { ReactComponent as History } from './assets/icons/history.svg'
import { ReactComponent as Phone } from './assets/icons/phone.svg'
import { ReactComponent as Email } from './assets/icons/email.svg'
import { ReactComponent as Facebook } from './assets/icons/facebook.svg'
import { ReactComponent as Telegram } from './assets/icons/telegram.svg'
import { ReactComponent as Youtube } from './assets/icons/youtube.svg'
import { ReactComponent as Smartphone } from './assets/icons/smartphone.svg'
import { ReactComponent as Key } from './assets/icons/key.svg'
import { ReactComponent as Bell } from './assets/icons/bell.svg'
import { ReactComponent as BellNoNotifications } from './assets/icons/bell-no-notifications.svg'
import { ReactComponent as Picture } from './assets/icons/picture.svg'
import { ReactComponent as Check } from './assets/icons/check.svg'
import { ReactComponent as Error } from './assets/icons/error.svg'
import { ReactComponent as NoImage } from './assets/icons/no-image.svg'
import { ReactComponent as Grid } from './assets/icons/grid.svg'
import { ReactComponent as Minus } from './assets/icons/minus.svg'
import { ReactComponent as Plus } from './assets/icons/plus.svg'
import { ReactComponent as Trash } from './assets/icons/trash.svg'
import { ReactComponent as EmptyCart } from './assets/icons/empty-cart.svg'
import { ReactComponent as FullCart } from './assets/icons/full-cart.svg'
import { ReactComponent as CheckBox } from './assets/icons/checkbox.svg'
import { ReactComponent as GridView } from './assets/icons/grid-view.svg'
import { ReactComponent as ListView } from './assets/icons/list-view.svg'
import { ReactComponent as CloseMenu } from './assets/icons/close-menu.svg'
import { ReactComponent as Gavel } from './assets/icons/gavel.svg'
import { ReactComponent as AccessTime } from './assets/icons/access-time.svg'
import { ReactComponent as SwapHoriz } from './assets/icons/swap-horiz.svg'
import { ReactComponent as Work } from './assets/icons/work.svg'
import { ReactComponent as CalendarEmpty } from './assets/icons/calendar-empty.svg'
import { ReactComponent as Exit } from './assets/icons/exit.svg'

export const ArrowIcon = ArrowBlack
export const ContractIcon = Contract
export const MarketIcon = Market
export const PlanIcon = Plan
export const QualificationIcon = Qualification
export const TenderIcon = Tender
export const FilterIcon = Filter
export const SearchIcon = Search
export const CloseBadgeIcon = CloseBadge
export const UserIcon = User
export const UserGreyIcon = UserGrey
export const BurgerIcon = Burger
export const CloseIcon = Close
export const InfoIcon = Info
export const StarIcon = Star
export const PrintIcon = Print
export const EyeIcon = Eye
export const DateIcon = CalendarPlus
export const CheckWhiteIcon = CheckWhite
export const DropdownIcon = Dropdown
export const AttentionIcon = Attention
export const SettingsIcon = Settings
export const UserMenuNotificationsIcon = UserMenuNotifications
export const UserMenuExitIcon = UserMenuExit
export const DocumentIcon = Document
export const DownloadIcon = Download
export const UploadIcon = Upload
export const WinnerIcon = Winner
export const MinusCircleIcon = MinusCircle
export const HistoryIcon = History
export const PhoneIcon = Phone
export const EmailIcon = Email
export const FacebookIcon = Facebook
export const TelegramIcon = Telegram
export const YoutubeIcon = Youtube
export const SmartphoneIcon = Smartphone
export const KeyIcon = Key
export const BellIcon = Bell
export const BellNoNotificationsIcon = BellNoNotifications
export const PictureIcon = Picture
export const CheckIcon = Check
export const ErrorIcon = Error
export const NoImageIcon = NoImage
export const GridIcon = Grid
export const MinusIcon = Minus
export const PlusIcon = Plus
export const TrashIcon = Trash
export const EmptyCartIcon = EmptyCart
export const FullCartIcon = FullCart
export const CheckBoxIcon = CheckBox
export const GridViewIcon = GridView
export const ListViewIcon = ListView
export const CloseMenuIcon = CloseMenu
export const GavelIcon = Gavel
export const AccessTimeIcon = AccessTime
export const SwapHorizIcon = SwapHoriz
export const WorkIcon = Work
export const CalendarEmptyIcon = CalendarEmpty
export const ExitIcon = Exit
