import React from 'react'
import * as Styled from './ui-follow-us.styled'
import { useTranslation, withTranslation } from 'react-i18next'
import { FacebookIcon, TelegramIcon, YoutubeIcon } from '../core/icons'

const UiFollowUs: React.FC = () => {
  const { t } = useTranslation()
  const socials = [
    {
      link: 'https://facebook.com/derzhzakupivli.online',
      alt: 'Facebook',
      src: <FacebookIcon />
    },
    {
      link: 'https://t.me/news_dzo',
      alt: 'Telegram',
      src: <TelegramIcon />
    },
    {
      link: 'https://www.youtube.com/channel/UClVF5I6GYxg9OJveqsHzc6w',
      alt: 'Youtube',
      src: <YoutubeIcon />
    }
  ]

  return (
    <Styled.SocialsWrapper>
      {t('Слідкуйте за нами')}
      <Styled.SocialsList>
        {socials.map((item) => (
          <li key={item.alt}>
            <a href={item.link} target='_blank' rel="noopener noreferrer nofollow" aria-label={item.alt}>
              {item.src}
            </a>
          </li>
        ))}
      </Styled.SocialsList>
    </Styled.SocialsWrapper>
  )
}

export default withTranslation('dzo')(UiFollowUs)
